import React from 'react';

import Header from '../components/Header/Header';
import HeroHistory from '../components/HeroHistory/HeroHistory';
import HistoryPoem from '../components/HistoryPoem/HistoryPoem';
import Hero from '../components/Hero/Hero';
import OurHistory from '../components/OurHistory/OurHistory';
import Footer from '../components/Footer/Footer';
// import BgDesktopSrc from '../images/hero-recipes-bg-desktop.jpg';
import BgDesktopSrc from '../images/hero-history-bg-desktop.jpg';
import SEO from '../components/SEO/SEO';
import CookieDisclaimer from '../components/CookieDisclaimer/CookieDisclaimer';

const HistoryPage = () => {
  return (
    <>
      <SEO pageName="history" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header />
        <main id="main" tabIndex="-1">
          <Hero
            img={{ mobile: null, desktop: BgDesktopSrc }}
            title="Our&nbsp;History"
          />
          <OurHistory />
          <HeroHistory />
          <HistoryPoem />
        </main>
        <Footer />
      </div>
      <CookieDisclaimer />
    </>
  );
};

export default HistoryPage;
