import styled from 'styled-components';

import BgDesktopSrc from '../../images/our-history-bg-desktopOLD.jpg';
import StampSrc from '../../images/stamp-navy.svg';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #383a4d;
  background-image: url(${BgDesktopSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 1023px) {
    padding: 48px 0;
  }

  @media (min-width: 1024px) {
    padding: 96px 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1158px;
  background-image: url(${StampSrc});
  background-repeat: no-repeat;

  @media (max-width: 1023px) {
    padding: 0 48px;
    background-position-x: calc(100% + 65px);
    background-position-y: 27%;
    background-size: 140px;
  }

  @media (min-width: 1024px) {
    padding: 0 24px;
    background-position: 144px center;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: #e9dfd0;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;
  line-height: 50px;

  @media (max-width: 639px) {
    font-size: 24px;
  }

  @media (min-width: 640px) {
    font-size: 32px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 50px;
  }
`;

export const TitleImg = styled.img`
  @media (max-width: 1023px) {
    max-width: 48px;
    margin-bottom: 8px;
  }
`;

export const TitleText = styled.h2`
  @media (min-width: 1024px) {
    margin-left: 19px;
  }
`;

export const ItemImg = styled.img`
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 640px;
    margin-bottom: 32px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    object-fit: contain;
  }
`;

export const ItemTitle = styled.h3`
  color: #d1914c;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;

  @media (max-width: 1023px) {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 1024px) {
    position: relative;
    font-size: 32px;
    line-height: 33px;
  }
`;

export const ItemDesc = styled.p`
  color: #fff;

  @media (max-width: 1023px) {
    max-width: 90%;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    position: relative;
    max-width: 500px;
    padding-top: 16px;
    margin-left: 88px;
    font-size: 18px;
    line-height: 35px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    align-items: center;
  }

  @media (min-width: 1024px) {
    position: relative;
    height: 400px;
  }

  &.--item1 {
    @media (min-width: 1024px) {
      padding-left: 70px;

      ${ItemImg} {
        right: 0;
        height: 400px;
      }

      ${ItemTitle} {
        max-width: 620px;
      }
    }
  }

  &.--item2 {
    @media (min-width: 1024px) {
      align-items: flex-end;
      margin-top: 46px;
      padding-right: 70px;

      ${ItemImg} {
        left: 0;
        height: 400px;
      }

      ${ItemTitle} {
        max-width: 538px;
      }
    }
  }

  &.--item3 {
    @media (min-width: 1024px) {
      margin-top: 63px;
      padding-left: 70px;

      ${ItemImg} {
        right: 0;
        height: 400px;
      }

      ${ItemDesc} {
        max-width: 410px;
        margin-top: 150px;
        margin-left: 114px;
        text-align: right;
      }
    }
  }

  &.--item4 {
    @media (max-width: 1023px) {
      ${ItemImg} {
        width: 70%;
        max-width: 440px;
      }
    }

    @media (min-width: 1024px) {
      align-items: flex-end;
      height: 550px;
      margin-top: 90px;

      ${ItemImg} {
        left: 0;
        height: 550px;
      }

      ${ItemTitle} {
        max-width: 460px;
      }
    }
  }
`;

export const More = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 131px;
  height: 48px;
  margin-top: 20px;
  color: #ce9b61;
  font-family: 'DharmaGothic';
  font-size: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid #ce9b61;
`;

export const Signature = styled.img`
  height: 60px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 639px) {
    max-width: 250px;
  }
`;
