import React, { useRef, useState } from 'react';

import {
  Root,
  Container,
  Body,
  Title,
  Text,
  Button,
  BookImg,
} from './HistoryPoem.style';

import BookSrc from '../../images/book.png';

import ModalPoem from '../ModalPoem/ModalPoem';

const HistoryPoem = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  const buttonRef = useRef(null);

  const handleClose = () => {
    setIsShowModal(false);

    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }, 0);
  };

  return (
    <Root id="poem">
      <Container>
        <Body>
          <Title>A Legacy of Renegades</Title>
          <Text>
            Our collective story told through poetry to express the incredible
            life of this heroic man and the legacy that carried through the
            generations to inspire the bourbon named in his honor.
          </Text>
          <Button ref={buttonRef} onClick={() => setIsShowModal(true)}>
            Read Poem
          </Button>
        </Body>
        <BookImg
          src={BookSrc}
          alt="THE LEGEND OR James OWNBY, A LEGACY OF RENEGADES LOST AMONGST THE AGES LIES A STORY SELDOM TOLD OF AN ANCIENT FAMILY SECRET NEAR A LONESOME OAK FILLED GROVE"
          onClick={() => setIsShowModal(true)}
        />
      </Container>

      {isShowModal && <ModalPoem onClose={handleClose} />}
    </Root>
  );
};

export default HistoryPoem;
